import { tns } from "tiny-slider/src/tiny-slider.module";

if (document.querySelector('.slide-wrapper')) {

    var slider = tns({
        container: ".slide-wrapper",
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 1,
            },
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false,
        autoHeight: true,
    });

    if (document.querySelector('.prev')) {
        document.querySelector('.prev').onclick = function() {
            slider.goTo('prev');
        };
    }

    if (document.querySelector('.next')) {
        document.querySelector('.next').onclick = function() {
            slider.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-conheca')) {

    var slider2 = tns({
        container: ".slide-wrapper-conheca",
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: true,
        autoplayButton: false,
        controls: false
    });


    if (document.querySelector('.prev-conheca')) {
        document.querySelector('.prev-conheca').onclick = function() {
            slider2.goTo('prev');
        };
    }

    if (document.querySelector('.next-conheca')) {

        document.querySelector('.next-conheca').onclick = function() {
            slider2.goTo('next');
        };
    }

}

if (document.querySelector('.slide-wrapper-historia')) {

    var slider3 = tns({
        container: ".slide-wrapper-historia",
        responsive: {
            0: {
                items: 1
            },
            992: {
                items: 2
            },
            1600: {
                items: 3
            }
        },
        autoplay: true,
        autoplayTimeout: 15000,
        mouseDrag: true,
        loop: true,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        nav: false,
        gutter: 30,
        autoplayButton: false,
        controls: false
    });

    if (document.querySelector('.next-historia')) {

        document.querySelector('.next-historia').onclick = function() {
            slider3.goTo('next');
        };
    }

    if (document.querySelector('.prev-historia')) {

        document.querySelector('.prev-historia').onclick = function() {
            slider3.goTo('prev');
        };
    }

}
